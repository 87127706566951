<script>
  export let companion = false;

  import { createEventDispatcher } from "svelte";
  import { _ } from "svelte-i18n";
  const dispatch = createEventDispatcher();

  const handleChange = (event) => {
    companion = event.target.checked;
    dispatch("change", companion);
  };

  let open = false;
  function toggleMenu() {
    open = !open;
  }
</script>

<ul class="products">
  <li>
    <input
      type="checkbox"
      bind:checked={companion}
      id="companion"
      name="companion"
      on:change={handleChange}
    />
    <label class="label_item" for="companion"
      >{@html $_("products.Add Course Companion (optional)")}</label
    >
    <button
      class="expander"
      style="background: {// selected and menu not open
      companion && !open ? '#113433' : 'none'} !important;
      {// list is open
      open ? 'width: 60px' : 'width: 130px'} !important;"
      on:click|preventDefault={() => toggleMenu}
    >
      {#if open}
        <div
          style="cursor: pointer; float: right; margin-right: 28px;"
          on:keydown={(event) => {
            if (event.key === "Enter" || event.key === "Space") {
              toggleMenu();
            }
          }}
          on:click|preventDefault={() => toggleMenu()}
        >
          −
        </div>
      {:else}
        <img
          class="more-info"
          alt="more-info"
          style="cursor: pointer"
          src={$_("choice.more-info-url")}
          on:click|preventDefault={() => toggleMenu()}
          on:keydown={(event) => {
            if (event.key === "Enter" || event.key === "Space") {
              toggleMenu();
            }
          }}
        />
      {/if}
    </button>
    <div class="details" class:open>
      <div class="picture">
        <img src="/images/product-select/enrolpage_cc.jpg" alt="" />
      </div>
      <div class="blurb">
        <br />
        {@html $_("products.course_companion_description")}
      </div>
    </div>
  </li>
</ul>

<style>
  @import "../styles/variables.css";

  .products,
  .details img {
    border: 2px solid var(--primary);
    border-radius: 4px;
  }

  li {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid var(--primary);
  }

  li:last-child {
    border-bottom: none;
  }

  label {
    display: flex;
    align-items: center;
    flex: 1;
    color: var(--primary);
    font-weight: bold;
    margin: 0;
  }

  label::before {
    content: "☐";
    font-size: 1.5rem;
    margin: 0 1rem;
  }

  input:checked + label {
    opacity: 1;
    background-color: var(--primary);
    color: var(--white);
  }

  input:checked + label::before {
    content: "☑";
  }

  .expander {
    border: none;
    background: none;

    font-size: 1.5rem;
    font-weight: bold;
    padding: 0.75rem 0;
    width: 130px;

    color: var(--primary);
  }

  input {
    display: none;
  }

  .details {
    width: 100%;
    padding: 0.5rem 1rem 1rem;

    display: none;
    align-items: start;
  }

  .details.open {
    display: block;
  }

  .details img {
    max-width: 100%;
  }

  .details .blurb {
    text-align: justify;
  }

  @media (max-width: 45rem) {
    .details {
      flex-direction: column-reverse;
      align-items: center;
    }

    .details img {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-top: 1rem;
    }

    .details .blurb {
      hyphens: auto;
    }
  }
</style>
