<script>
  import { updateSession, getRegion } from "../../utils.js";
  import { params, redirect } from "@roxi/routify";
  import { getLocaleFromNavigator } from "svelte-i18n";

  export let scoped;

  let { product, variant = "full", lang } = $params;
  function defaultLanguage() {
    let localeFromBrowser = getLocaleFromNavigator();
    let localeFromStorage = localStorage.getItem("enrol-app-locale");
    let localeDefault = "en";

    if (lang) {
      return lang;
    }

    if (localeFromStorage) {
      return localeFromStorage;
    }

    if (localeFromBrowser) {
      return localeFromBrowser.slice(0, 2);
    }

    return localeDefault;
  }

  if (product) {
    const { countries, prices, session, countryCode } = scoped;

    let language = defaultLanguage();
    localStorage.setItem("enrol-app-locale", language);
    const region = getRegion(countries, countryCode);
    let regionalPrices = [];
    if (language == "en") {
      regionalPrices = prices.filter(
        (price) => price.language === language && price.region.code === region
      );
    } else {
      regionalPrices = prices.filter((price) => price.language === language);
    }
    const productPrices = regionalPrices.filter(
      (price) => price.variant.product.code === product
    );
    const [price] = productPrices.filter(
      (price) => price.variant.code === variant
    );
    if (price) {
      updateSession(session, {
        selectedPriceID: price.id,
        selectedPriceRegionCode: price.region.code,
      });
    }
  }

  $redirect("./package");
</script>
