<script>
  import { updateSession, getPrice, getRegion } from "../../utils.js";
  import { goto } from "@roxi/routify";
  import { _ } from "svelte-i18n";
  import BardicTypeSelect from "../../components/BardicTypeSelect.svelte";
  import CountryName from "../../components/CountryName.svelte";
  import CurrencyConverter from "../../components/CurrencyConverter.svelte";
  import PriceDisplay from "../../components/PriceDisplay.svelte";
  import ProductSelect from "../../components/ProductSelect.svelte";
  import CompanionSelect from "../../components/CompanionSelect.svelte";
  import VariantSelect from "../../components/VariantSelect.svelte";
  import LocaleSetting from "../../locale/LocaleSetting.svelte";
  import ConsentModal from "../../components/ConsentModal.svelte";
  import ContactUsForm from "../../components/ContactUsForm.svelte";

  export let scoped;
  var {
    countries,
    symbols,
    rates,
    prices,
    session,
    countryCode,
    countryCodeIsSelected,
  } = scoped;

  let isOpen = false;
  const region = getRegion(countries, countryCode);
  let regionalPrices = filterPrices(localStorage.getItem("enrol-app-locale"));
  $: regionalPrices = filterPrices(localStorage.getItem("enrol-app-locale"));

  let initialPrice = getPrice(regionalPrices, $session.selectedPriceID);
  $: initialPrice = getPrice(regionalPrices, $session.selectedPriceID);

  let companion = $session.companion || false;
  $: {
    updateSession(session, {
      companion: companion,
    });
  }
  function handleCompanionChange(event) {
    companion = event.detail;
  }

  let type;
  type = initialPrice
    ? initialPrice.variant.product.code === "intro"
      ? "intro"
      : "join"
    : null;

  let productIntroDescription;
  let productIntroImage;
  let pricesForType;
  $: {
    if (type === "intro") {
      pricesForType = regionalPrices.filter(
        (price) => price.variant.product.code === "intro"
      );
      companion = false;
      if (initialPrice) {
        productIntroDescription = initialPrice.variant.product.description;
        productIntroImage = initialPrice.variant.product.thumbnail;
      }
    } else if (type === "join") {
      pricesForType = regionalPrices.filter(
        (price) => price.variant.product.code !== "intro"
      );
    } else {
      pricesForType = [];
    }
  }

  let product;
  product = initialPrice ? initialPrice.variant.product : null;
  if (initialPrice && initialPrice.variant.product.code) {
    productIntroDescription = initialPrice.variant.product.description;
    productIntroImage = initialPrice.variant.product.thumbnail;
  }

  let pricesForProduct;
  $: pricesForProduct =
    pricesForType && product
      ? pricesForType.filter((price) => price.variant.product.id === product.id)
      : [];

  let variant;
  variant = initialPrice ? initialPrice.variant : null;

  let price;
  $: {
    if (type === "intro") {
      price = pricesForType[0];
      if (price) {
        productIntroDescription = price.variant.product.description;
        productIntroImage = price.variant.product.thumbnail;
      }
    } else {
      price =
        pricesForProduct && variant
          ? pricesForProduct.find((price) => price.variant.id === variant.id)
          : null;
    }
    let selectedPrice;
    let selectedRegion;
    if (price) {
      selectedPrice = price.id;
      selectedRegion = price.region.code;
    }
    updateSession(session, {
      selectedPriceID: selectedPrice,
      selectedPriceRegionCode: selectedRegion,
    });
  }

  let toggleCurrencyConverter;
  let language;
  if (localStorage.getItem("enrol-app-locale")) {
    language = localStorage.getItem("enrol-app-locale");
  } else {
    language = "en";
  }

  let baseCurrency;
  $: baseCurrency = changeBaseCurrency(language);

  let productDetails;
  $: productDetails = filterProducts(language);

  let introPackProduct = getIntroPackProduct(language);
  $: introPackProduct = getIntroPackProduct(language);

  function filterProducts(language) {
    return language === "en"
      ? {
          text: {
            description: "products.printed",
            blurb: "products.bardic_description_printed",
          },
          online: {
            description: "products.online",
            blurb: "products.bardic_description_online",
          },
          text_online: {
            description: "products.Both Print & Online",
            blurb: "products.bardic_description_online_and_printed",
          },
          audio: {
            description: "Audio CD",
            blurb: `
            Receive the full Bardic training Course (including the Introductory Pack for
            free) posted monthly over 12 months, and by the end of the year you will have
            received 51 Gwersi (lessons) in Audio CD format, plus The Bardic Companion,
            The Book of Ritual, 8 festival ritual booklets, an Index, a Meditation and
            Pronunciation Guide CD, the monthly magazine Touchstone, access to
            private web forums and a personal mentor`,
          },
          text_audio: {
            description: "Both Print & Audio CD",
            blurb: `
            Receive the full Bardic training Course (including the Introductory Pack
            for free) posted monthly over 12 months, and by the end of the year you will
            have received 51 Gwersi (lessons) as booklets and in Audio CD format,
            The Bardic Companion, The Book of Ritual, 8 festival ritual booklets,
            an Index, a Meditation and Pronunciation Guide CD, the monthly
            magazine Touchstone, access to private web forums and a personal
            mentor`,
          },
        }
      : {
          text: {
            description: "products.printed",
            blurb: "products.bardic_description_printed",
          },
          text_online: {
            description: "products.Both Print & Online",
            blurb: "products.bardic_description_online_and_printed",
          },
        };
  }

  function changeBaseCurrency(language) {
    if (language == "en") {
      return "gbp_rates";
    }
    return "eur_rates";
  }

  function filterPrices(language) {
    if (language == "en") {
      return prices.filter(
        (price) => price.language === language && price.region.code === region
      );
    }
    return prices.filter((price) => price.language === language);
  }

  function handleLanguageChange(event) {
    productDetails = filterProducts(event.detail);
    regionalPrices = prices.filter((price) => price.language === event.detail);
    baseCurrency = changeBaseCurrency(event.detail);
    regionalPrices = filterPrices(event.detail);
    initialPrice = getPrice(regionalPrices, $session.selectedPriceID);
    introPackProduct = getIntroPackProduct(event.detail);
  }

  let isConsentGiven = false;

  function formSubmit() {
    const consentModal = new ConsentModal({
      target: document.body,
      props: {
        isOpen: true,
      },
    });
    consentModal.$on("consent", (event) => {
      isConsentGiven = event.detail.agreed;
      consentModal.$destroy();
      if (isConsentGiven) {
        updateSession(session, {
          selectedPriceID: price.id,
          selectedPriceRegionCode: price.region.code,
          companion: companion,
          isConsentGiven: isConsentGiven,
        });
        $goto("./details");
      }
    });
  }

  function getIntroPackProduct(selectedLanguage) {
    return regionalPrices.filter(
      (price) =>
        price.variant.product.code === "intro" &&
        price.language === selectedLanguage
    )[0].variant.product;
  }
</script>

<LocaleSetting {language} on:languageChange={handleLanguageChange} />
<form on:submit|preventDefault={formSubmit}>
  <BardicTypeSelect bind:type bind:introPackProduct />

  {#if type && type != "intro"}
    <section>
      <h2>{@html $_("choice.Which format do you prefer ?")}</h2>
      <ProductSelect
        prices={pricesForType}
        details={productDetails}
        order={["online", "text", "text_online", "audio", "text_audio"]}
        bind:product
      />
    </section>

    <VariantSelect prices={pricesForProduct} bind:variant>
      <div class="monthly-not-available" slot="monthly-not-available">
        {$_("choice.Online learning is only available with Full Payment")}
      </div>
    </VariantSelect>

    <br />
    <br />
    <section>
      <CompanionSelect bind:companion on:change={handleCompanionChange} />
    </section>
  {/if}
  {#if type && type == "intro"}
    <br />
    <br />
    <br />
    <section>
      <ul class="products">
        <li>
          <div class="details">
            <div class="picture">
              {#if productIntroImage}
                <img src={productIntroImage} alt="" />
              {:else}
                <img src="/images/product-select/intro.jpeg" alt="" />
              {/if}
            </div>
            <div class="blurb">
              {#if productIntroDescription}
                {@html productIntroDescription}
              {:else}
                {@html $_("products.intro_pack_description")}
              {/if}
            </div>
          </div>
        </li>
      </ul>
    </section>
  {/if}
  <section class="links">
    <div class="change-location">
      <p>
        {#if countryCodeIsSelected}
          {$_("choice.selected_location_and_currency")}: <CountryName
            {countries}
            {countryCode}
          />
        {:else}
          {$_("choice.detected_location_and_currency")}: <CountryName
            {countries}
            {countryCode}
          />
        {/if}
      </p>
      <a href="/bardic/country">&gt; {$_("choice.change_my_location")}</a>
    </div>

    {#if price}
      <div class="currency-converter">
        <p>{$_("choice.click_to_change_currency")}</p>
        <button on:click|preventDefault={toggleCurrencyConverter}
          >{$_("choice.currency_converter")}</button
        >
      </div>
    {/if}

    <button
      class="open"
      on:click|preventDefault={() => {
        isOpen = true;
      }}>> Feedback / Problems? Contact us</button
    >

    <br /><br /><br /><br />
  </section>

  <ContactUsForm bind:isOpen />

  <CurrencyConverter
    bind:toggle={toggleCurrencyConverter}
    {companion}
    {baseCurrency}
    {countries}
    {symbols}
    {rates}
    {price}
    {countryCode}
  />

  <section class="btn-container">
    {#if price}
      <h2 class="total-price-title">{$_("choice.total_price_of_selection")}</h2>
    {/if}
    <div class="btn-container__inside">
      <PriceDisplay {price} {companion} />
      <button disabled={!price} class="btn btn--primary"
        >{$_("choice.next")}</button
      >
    </div>
  </section>
</form>

<style>
  @import "../../styles/variables.css";

  .links {
    margin-top: 2em;
  }

  .change-location,
  .currency-converter,
  .monthly-not-available {
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
  }

  .monthly-not-available {
    text-align: center;
  }

  .currency-converter button {
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
  }

  .currency-converter button::before {
    content: "> ";
  }

  .currency-converter button:hover {
    cursor: pointer;
  }

  .products,
  .details img {
    border: 2px solid var(--primary);
    border-radius: 4px;
  }

  li {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 2px solid var(--primary);
  }

  li:last-child {
    border-bottom: none;
  }

  .details {
    width: 100%;
    padding: 1rem 1rem 1rem;
    display: flex;
    align-items: start;
  }

  .details img {
    max-width: 200px;
    margin-right: 1rem;
  }

  .details .blurb {
    text-align: justify;
  }

  @media (max-width: 45rem) {
    .details {
      flex-direction: column-reverse;
      align-items: center;
    }

    .details img {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-top: 1rem;
    }

    .details .blurb {
      hyphens: auto;
    }
  }

  .total-price-title {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: unset;
    padding-right: 360px;
  }

  @media (max-width: 718px) {
    .total-price-title {
      margin-left: 10px;
      padding-right: 0px;
    }
  }

  .open {
    margin-bottom: var(--baseline);
    border: 0;
    font: inherit;
    color: inherit;
    background: inherit;
    letter-spacing: 1px;
    color: var(--primary-light);
    padding: 0.5em 0;
    font-style: italic;
    font-size: 12px;
    cursor: pointer;
  }
</style>
