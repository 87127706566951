<script>
  import { clearSession } from "../../utils.js";
  import { goto, redirect } from "@roxi/routify";

  import OrderSummary from "../../components/OrderSummary.svelte";
  import PaymentForm from "../../components/PaymentForm.svelte";

  export let scoped;
  const { countries, price, session, countryCode, isOnline, accessibleStages } =
    scoped;
  const accessible = accessibleStages.includes("payment");
  const companion = $session.companion;

  if (!accessible) $redirect("./details");

  function clearOut(order) {
    try {
      clearSession($session);
    } finally {
      const search = new URLSearchParams(
        [["orderID", order.id], isOnline && ["online", 1]].filter(Boolean)
      );

      $goto(`/success/bardic?${search}`);
    }
  }
</script>

{#if accessible}
  <OrderSummary {countries} {price} {countryCode} {isOnline} {companion} />
  <PaymentForm
    {price}
    customerDetails={$session.customerDetails}
    {countryCode}
    {companion}
    isConsentGiven={$session.isConsentGiven}
    on:order={({ detail: order }) => clearOut(order)}
  />
{/if}
