<script>
  import { _ } from "svelte-i18n";
  export let type, introPackProduct;
</script>

<section>
  <h2>{@html $_("choice.I_would_like_to_choose_please_select")}</h2>
  <ul class="packages">
    <li>
      <input
        class="radio_item"
        type="radio"
        bind:group={type}
        value="intro"
        id="type-intro"
        name="package"
      />
      <label class="label_item" for="type-intro">
        {#if introPackProduct?.title !== introPackProduct?.name}
          {introPackProduct?.title}
        {:else}
          {@html $_("choice.the Introductory Pack")}
        {/if}
      </label>
    </li>
    <li>
      <input
        class="radio_item"
        type="radio"
        bind:group={type}
        value="join"
        id="type-join"
        name="package"
      />
      <label class="label_item" for="type-join">
        {@html $_("choice.the Full Course & Membership")}
      </label>
    </li>
  </ul>
</section>

<style>
  @import "../styles/variables.css";

  .packages {
    display: flex;
    align-items: stretch;
  }

  .packages li {
    list-style-type: none;
    flex: 1;
  }

  .packages li:last-of-type {
    margin-left: 4%;
  }

  .packages .radio_item {
    display: none !important;
  }

  .packages .label_item {
    border: 2px solid var(--primary);
    padding: var(--baseline);
    color: var(--primary);
    font-weight: bold;
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: center;
    margin: 0;
    border-radius: 4px;
  }

  .packages .radio_item:checked + label {
    opacity: 1;
    background-color: var(--primary);
    color: var(--white);
  }

  .packages label {
    cursor: pointer;
  }
</style>
