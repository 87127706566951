<script>
  import { updateSession } from "../../utils.js";
  import { goto, redirect } from "@roxi/routify";
  import { _ } from "svelte-i18n";
  import DetailsForm from "../../components/DetailsForm.svelte";
  import OrderSummary from "../../components/OrderSummary.svelte";
  import InstalmentAgreement from "../../components/InstalmentAgreement.svelte";

  export let scoped;
  const { countries, price, session, countryCode, isOnline, accessibleStages } =
    scoped;
  const accessible = accessibleStages.includes("details");
  if (!accessible) $redirect("./package");

  function formSubmit({ customerDetails }) {
    updateSession(session, { customerDetails });
    $goto("./payment");
  }
</script>

{#if accessible}
  <OrderSummary
    {countries}
    {countryCode}
    {price}
    {isOnline}
    companion={$session.companion}
  />
  <DetailsForm
    {countries}
    {countryCode}
    {price}
    customerDetails={$session.customerDetails}
    companion={$session.companion}
    {formSubmit}
  >
    <div slot="before">
      {#if isOnline && price.variant.code === "monthly"}
        <InstalmentAgreement>
          {$_("details.agree_monthly")}
        </InstalmentAgreement>
      {/if}
    </div>
  </DetailsForm>
{/if}
